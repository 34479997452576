












































import { defineComponent, reactive, ref } from '@vue/composition-api';
import { InputBox, LoadingSpinner } from '@/components';
import { authSearvice } from '@/services';

import { removeToken, setToken } from '@/utils';
import { RountName } from '@/router';

export default defineComponent({
  name: 'AuthLogin',
  props: {
    from: {
      type: String,
      default: '',
    },
  },
  components: {
    InputBox,
    LoadingSpinner,
  },
  setup(props, { root }) {
    const isLoading = ref(false);
    const form = reactive({
      email: '',
      password: '',
    });

    async function onSubmitClicked() {
      if (!form.email) {
        alert('이메일을 입력해 주세요.');
        return;
      }
      if (!form.password) {
        alert('비밀번호를 입력해 주세요.');
        return;
      }
      if (form.password.length < 8) {
        alert('비밀번호 8자이상 입력해 주세요.');
        return;
      }

      if (isLoading.value) return;

      isLoading.value = true;

      try {
        const token = await authSearvice.login({ email: form.email, password: form.password });

        removeToken();

        setToken(token);

        if (props.from === RountName.AuthPasswordReset) {
          root.$router.push({ name: RountName.AuthPasswordEdit });
          return;
        }

        window.location.href = '/';
      } catch (e) {
        // pass
        // const error = e as AxiosError;
        // if (error.response?.data.message) {
        //   alert(error.response.data.message);
        // }
      } finally {
        isLoading.value = false;
      }
    }

    return {
      RountName,

      isLoading,

      form,

      onSubmitClicked,
    };
  },
});
